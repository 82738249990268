// authSlice.ts

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countSolicitadas: 0,
  countDevueltas: 0,
  countAtender: 0,
  countCanceladas: 0,
  countReasignar: 0,
  countProceso: 0,
  token: null,
  rol: 0,
  routes: [],
  indexUpdate: 1,
  moduleName: "",
  userName: "",
  id: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNotificacion(state, action) {
      const { countProceso, countDevueltos, countAtender } = action.payload;
      state.countAtender = countAtender;
      state.countDevueltas = countDevueltos;
      state.countProceso = countProceso;
    },
    setDataAuht(state, action) {
      const { token, rol, routes, userName, id } = action.payload;
      state.token = token;
      state.rol = rol;
      state.routes = routes;
      state.userName = userName;
      state.id = id;
    },
    setUpdatePage(state, action) {
      state.indexUpdate = state.indexUpdate + action.payload;
    },
    setHeaderName(state, action) {
      state.moduleName = action.payload;
    },
    logout(state, action) {
      state.token = null;
    },
  },
});

export const {
  setNotificacion,
  setDataAuht,
  setUpdatePage,
  logout,
  setHeaderName,
} = authSlice.actions;

export default authSlice.reducer;
