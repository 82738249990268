import { PlusCircle } from "lucide-react";
import { useMemo, useState } from "react";

export const SolvenciaCalculada = ({ data }) => {
  const [solvenciaNomipay, setSolvenciaNomipay] = useState(0);

  const total = useMemo(() => {
    if (!data) return 0;
    return data.credits.reduce((a, b) => {
      return a + b.amountMenor;
    }, 0);
  }, [data]);

  return (
    <>
      <div className="mt-10 flex  w-full flex-row items-center justify-center gap-4 gap-x-20">
        <div className="flex flex-col rounded-lg border p-4">
          <label htmlFor="">Solvencia de pagos</label>
          <strong>{total.toFixed(2)}</strong>
        </div>

        <PlusCircle className="bg-primary/10 text-primary flex h-8 w-8 items-center justify-center rounded-full"></PlusCircle>

        <input
          type="number"
          placeholder="solvencia nomipay"
          className="rounded-md border p-4"
          onChange={(event) => {
            const value = event.target.value;
            setSolvenciaNomipay(parseFloat(value));
          }}
        />

        <div className="flex items-center justify-center">
          <span className="text-xl font-medium">=</span>
        </div>

        <div className="flex">
          <div className="bg-primary/5 border-primary/20 rounded-xl border p-3 shadow-sm">
            <span className="mb-1 block text-sm text-gray-500">Resultado</span>
            <div className="text-primary text-xl font-semibold">
              {(total + solvenciaNomipay).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 flex flex-col">
        {data?.credits.map((item) => {
          return (
            <div className="flex flex-col rounded-lg bg-gray-200 p-4">
              <div className="flex flex-row gap-x-6 ">
                <span>
                  Credito id: <strong>{item.creditId}</strong>
                </span>
                <span>
                  Monto Menor: <strong>{item.amountMenor}</strong>
                </span>
              </div>

              <div className="mt-4 flex flex-col gap-2">
                {item.lastPayment.map((payment) => {
                  return (
                    <div className="flex flex-row gap-4">
                      <span>Fecha: {payment.movementDate}</span>
                      <span>Estatus: {payment.opStatus}</span>
                      <span>Monto: {payment.totalPaid}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
