export default function TabsReport({ active, setActive }) {
  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";

  return (
    <div className="flex">
      {[
        {
          text: "Ventas general",
          index: 1,
        },
        {
          text: "Reporte estatus",
          index: 2,
        },
        {
          text: "Reporte estatus asesor",
          index: 3,
        },
      ].map((item) => {
        return (
          <button
            className={`px-4 py-2  ${
              active === item.index ? getClassActive() : "bg-gray-200"
            }`}
            onClick={() => setActive(item.index)}
          >
            {item.text}
          </button>
        );
      })}
    </div>
  );
}
