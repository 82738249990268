import { useState } from "react";
import api from "service";

export const FormIp = ({setActiveModal}) => {
  const [ip, setIp] = useState("");

  const saveIP = async () => {
    try {
      await api.post("system/ip", { ip });
      setIp("");
    setActiveModal(null);
    } catch (error) {
      console.error("Error saving IP:", error);
    }
  };

  const fetchPublicIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const responseData = await response.json();
      setIp(responseData.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      {ip ? (
        <>
          <p className="mb-2 text-lg">La IP pública es {ip}</p>
          <button
            onClick={saveIP}
            className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
          >
            Guardar para dar acceso
          </button>
        </>
      ) : (
        <>
          <p className="mb-2 text-lg">
            Conéctate a la red y obtén la IP pública
          </p>
          <button
            onClick={fetchPublicIP}
            className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
          >
            Obtener IP pública
          </button>
        </>
      )}
    </div>
  );
};
