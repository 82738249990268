import { useEffect, useState } from "react";
import api from "service";
import Swal from "sweetalert2";

export const ConveniosDependencias = ({ isdependencias }) => {
  const [dependencias, setDependencias] = useState([]);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (isdependencias) getConvenios();
  }, [isdependencias]);

  const getConvenios = async () => {
    const data = await api.get("system/convenios");
    if (data) setDependencias(data);
  };

  const saveConvenio = async () => {
    if (!form.name) {
      return Swal.fire({
        title: "Error",
        text: "Ingresa el nombre del convenio",
      });
    }
    const respose = await api.post("system/convenios", form);
    console.log(respose);
    if (respose) {
      setForm({});
      return Swal.fire({
        title: "Success",
        text: "La dependencia se creo correctamente",
      });
    }
  };

  const saveDependencia = async () => {
    if (!form.name) {
      return Swal.fire({
        title: "Error",
        text: "Ingresa el nombre de la dependencia",
      });
    }

    if (!form.code) {
      return Swal.fire({
        title: "Error",
        text: "Ingresa el codigo",
      });
    }

    if (!form.idAgreements) {
      return Swal.fire({
        title: "Error",
        text: "Selecciona el convenio",
      });
    }

    const respose = await api.post("system/dependencias", form);
    if (respose) {
      setForm({});
      return Swal.fire({
        title: "Success",
        text: "La dependencia se creo correctamente",
      });
    }
  };

  return (
    <div className="flex flex-col items-start  bg-gray-100 ">
      {!isdependencias && (
        <div className="flex flex-col gap-4 p-4">
          <label htmlFor="">Ingresa el nombre del convenio</label>
          <input
            type="text"
            className=" w-full px-2 py-2"
            placeholder="Ingresa el nombre"
            onChange={(event) => {
              setForm({ name: event.target.value });
            }}
          />
          <button
            onClick={saveConvenio}
            className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
          >
            Guardar
          </button>
        </div>
      )}

      {isdependencias && (
        <div className="flex flex-col gap-4 p-4">
          <div className="flex flex-col">
            <label htmlFor="">Ingresa el nombre de la dependencia</label>
            <input
              type="text"
              className=" w-full px-2 py-2"
              placeholder="Ingresa el nombre"
              onChange={(event) => {
                setForm({ ...form, name: event.target.value });
              }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Ingresa el codigo de la dependencia</label>
            <input
              type="text"
              className=" w-full px-2 py-2"
              placeholder="Ingresa el codigo"
              onChange={(event) => {
                setForm({ ...form, code: event.target.value });
              }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Selecciona el convenio</label>
            <select
              onChange={(event) => {
                setForm({ ...form, idAgreements: event.target.value });
              }}
              value={form?.idAgreements}
            >
              <option value={""}>Selecciona un valor</option>
              {dependencias.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <button
            onClick={saveDependencia}
            className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
          >
            Guardar
          </button>
        </div>
      )}
    </div>
  );
};
