import ModalGeneral from "components/modal";
import { useState } from "react";
import api from "service";
import Swal from "sweetalert2";
import SimuladorC from "./simulador";
import CreateExcel from "excel/excel";
import { format } from "date-fns";

export function SimuladorMasivo() {
  const [file, setFile] = useState();
  const [ofertas, setOfertas] = useState();
  const [loading, setLoading] = useState(false);
  const [viewOferta, setViewOferta] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2 p-2">
        <label htmlFor="file">Cargar el excel</label>
        <input
          type="file"
          name=""
          id="file"
          onChange={(event) => {
            setFile(event.target.files[0]);
          }}
        />
        <button
          className="mt-10 w-[200px] rounded-[10px] bg-brand-500 p-2 text-white"
          disabled={loading}
          onClick={() => {
            if (!file) {
              return Swal.fire({
                title: "Error",
                text: "Selecciona el archivo",
              });
            }
            const form = new FormData();
            form.append("file", file);
            const headers = { "Content-Type": "multipart/form-data" };
            setLoading(true);
            api
              .post("solicitudes/simulador-masivo", form, headers)
              .then((res) => {
                if (res) {
                  setOfertas(res);
                }
              })
              .finally(() => {
                setFile(null);
                setLoading(false);
              });
          }}
        >
          {loading ? "Procesando" : "Realizar simulación"}
        </button>
      </div>
      {ofertas && (
        <div className="item-end flex flex-row justify-end">
          <button
            className="w-[300px] rounded-[10px] bg-brand-500 p-2 text-white"
            onClick={() => {
              const excel = new CreateExcel(
                `md_simulador_${format(new Date(), "yyyy_MM_dd")}`,
                [
                  { headerName: "rfc", field: "rfc" },
                  { headerName: "nomina", field: "nomina" },
                  { headerName: "dependencia", field: "dependencia" },
                  { headerName: "universo", field: "universo" },
                  { headerName: "ficha", field: "ficha" },
                  { headerName: "solvencia", field: "solvencia" },
                  { headerName: "genero", field: "genero" },
                  { headerName: "fechaIngreso", field: "fechaIngreso" },
                  { headerName: "fechaNacimiento", field: "fechaNacimiento" },
                  { headerName: "message", field: "message" },
                  { headerName: "oferta", field: "oferta" },
                ],
                ofertas.map((item) => {
                  return {
                    rfc: item.rfc,
                    nomina: item.nomina,
                    universo: item.universo,
                    ficha: item.ficha,
                    solvencia: item.solvencia,
                    genero: item.genero,
                    fechaIngreso: item.fechaIngreso,
                    fechaNacimiento: item.fechaNacimiento,
                    message: item.oferta.message,
                    oferta: JSON.stringify(item.oferta.prestamo),
                  };
                })
              );
              excel.createDefaultExcel();
            }}
          >
            DESCARGAR RESPUESTA EN EXCEL
          </button>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {ofertas &&
          ofertas.map((item) => {
            return (
              <div className="flex flex-col items-start justify-start gap-4 rounded-lg bg-white p-4">
                <div
                  className={`rounded-xs rounded-xs flex flex-wrap gap-2       p-4
            ${item.oferta.message ? "bg-red-300" : "bg-green-100"}`}
                >
                  <span>Rfc: {item.rfc}</span>
                  <span>Curp: {item.curp}</span>
                  <span>Nomia: {item.nomina}</span>
                  <span>Dependencia: {item.dependencia}</span>
                  <span>Universo: {item.universo}</span>
                  <span>ficha: {item.ficha}</span>
                  <span>sindicato: {item.sindicato}</span>
                  <span>solvencia: {item.solvencia}</span>
                  <span>genero: {item.genero}</span>
                  <span>fechaIngreso: {item.fechaIngreso}</span>
                  <span>fechaNacimiento: {item.fechaNacimiento}</span>
                </div>
                {!item.oferta.message && (
                  <button
                    className="w-full rounded-[10px] bg-brand-500 p-2 text-white"
                    onClick={() => {
                      if (item.oferta.message) {
                        return Swal.fire({
                          title: "Error",
                          text: item.oferta.message,
                        });
                      } else {
                        setViewOferta(item.oferta);
                      }
                    }}
                  >
                    Ver oferta
                  </button>
                )}
                <div>
                  <span>{item.oferta.message}</span>
                </div>
              </div>
            );
          })}
      </div>
      {viewOferta && (
        <ModalGeneral
          close={() => {
            setViewOferta(null);
          }}
          title={`Oferta`}
        >
          <SimuladorC oferta={viewOferta} />
        </ModalGeneral>
      )}
    </div>
  );
}
