import { useState } from "react";
import Swal from "sweetalert2";
import formIne, { validateForm } from "../field-ine";
import XMLViewer from "react-xml-viewer";

export function FormPruebaIne() {
  const [xmlStr, setXmlStr] = useState();
  const [ineRoot, setIneRoot] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <div className="mt-10 grid grid-cols-[1fr_3fr] grid-rows-[1fr] gap-2">
      <div className="flex  flex-col items-center px-2">
        <form
          className="flex flex-col px-10"
          onSubmit={async (event) => {
            event.preventDefault();
            const formData = new FormData(event.target);
            const data = Object.fromEntries(formData.entries());
            const validation = validateForm(data, formIne[0].requiredFields);
            if (!validation.valid) {
              return Swal.fire({
                icon: "error",
                title: validation.message,
              });
            }
            setLoading(true);
            setXmlStr(null);
            setIneRoot(null);
            const result = await formIne[0].onSubmit(formData);
            if (result) {
              setXmlStr(result.result);
              setIneRoot(result.xml);
            }
            setLoading(false);
          }}
        >
          {formIne[0].fields.map((item, index) => {
            return (
              <div className="flex flex-col" key={index}>
                <label htmlFor="">{item.label}</label>
                <input
                  type={item.type}
                  name={item.name}
                  id={item.name}
                  className="rounded-lg p-1"
                />
              </div>
            );
          })}

          <button
            className="mt-10 rounded-[10px] bg-brand-500 p-2 text-white"
            disabled={loading}
          >
            {loading ? "VALIDANDO" : "VALIDAR INE"}
          </button>
        </form>
      </div>
      <div
        className={`flex-col items-center justify-center gap-4 rounded-[20px] bg-blue-100 px-4 py-10 `}
      >
        <div>
          <span>Respuesta</span>
          {xmlStr && <XMLViewer xml={xmlStr} />}
        </div>
        <div>
          <span>Información enviada</span>
          {ineRoot && <div>{ineRoot}</div>}
        </div>
      </div>
    </div>
  );
}
