import { Cloud, Upload, List } from "lucide-react";

const ConfigCard = ({ title, onViewList, onUpload, btnNormal }) => {
  return (
    <div className="flex min-h-[280px] flex-col items-center justify-between gap-4 rounded-2xl border border-gray-100 bg-white p-8 shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="flex flex-col items-center gap-4">
        <Cloud className="h-16 w-16 text-custom-green" />
        <h2 className="text-xl font-medium text-gray-800">{title}</h2>
      </div>

      <div className="flex w-full flex-col gap-3">
        <button
          onClick={onViewList}
          className="hover:bg-primary/20 flex w-full items-center justify-center gap-2 rounded-lg bg-custom-green/10 px-4 py-2 text-custom-green transition-colors"
        >
          <List className="h-5 w-5" />
          Ver Lista
        </button>

        <button
          onClick={onUpload}
          className="hover:bg-primary/90 flex w-full items-center justify-center gap-2 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
        >
          {!btnNormal && <Upload className="h-5 w-5" />}
          {btnNormal ? "Actualizar" : " Subir Archivo"}
        </button>
      </div>
    </div>
  );
};

export default ConfigCard;
