import Filter from "components/filter";
import Grid from "components/grid";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useState } from "react";
import api from "service";

export default function StatusAsesor() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const handleFilter = () => {
    setLoading(true);
    return api
      .get(
        `/report/asesor-estatus-solicitudes?start=${startDate}&end=${endDate}`
      )
      .then((res) => {
        if (res) setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="p-4 duration-300">
      <Filter
        handleFilter={handleFilter}
        exportColumns={[
          { headerName: "Id Credito", field: "idCredito" },
          { headerName: "Estatus", field: "estatus" },
          { headerName: "Producto", field: "producto" },
          { headerName: "Cliente", field: "cliente" },
          { headerName: "Plazo", field: "plazo" },
          { headerName: "Prestamo", field: "prestamo" },
          { headerName: "Dispersado", field: "dipersado" },
          { headerName: "Dependencia", field: "dependencia" },
          { headerName: "Dia", field: "dia" },
          { headerName: "Mes", field: "mes" },
          { headerName: "Año", field: "anio" },
        ]}
        dataExport={data}
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          colums={[
            { name: "Id Credito", field: "idCredito" },
            { name: "Estatus", field: "estatus" },
            { name: "Producto", field: "producto" },
            { name: "Cliente", field: "cliente" },
            { name: "Plazo", field: "plazo" },
            { name: "Prestamo", field: "prestamo" },
            { name: "Dispersado", field: "dipersado" },
            { name: "Dependencia", field: "dependencia" },
            { name: "Dia", field: "dia" },
            { name: "Mes", field: "mes" },
            { name: "Año", field: "anio" },
          ]}
          data={data ? data : []}
        />
      )}
    </div>
  );
}
