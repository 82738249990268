import { useEffect, useMemo, useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
const TableUx = ({
  data,
  ComponentRow,
  colums,
  textFilter,
  keysFilter,
  setDataExport,
  itemsPerPageProps,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(itemsPerPageProps ?? 50);

  const getValueByPath = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
  };

  const dataGrid = useMemo(() => {
    if (!data) return [];
    return data.filter((item) => {
      if (keysFilter && keysFilter.length > 0) {
        return keysFilter.some((key) => {
          const value = getValueByPath(item, key);
          if (value) {
            return value
              .toString()
              .toLowerCase()
              .includes(textFilter.toLowerCase());
          }
          return false;
        });
      }
      return item;
    });
  }, [data, textFilter]);

  useEffect(() => {
    if (setDataExport) {
      setDataExport(dataGrid);
    }
  }, [dataGrid]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataGrid.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(dataGrid.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="container mx-auto mt-10">
      <table className="w-full border-collapse">
        <thead>
          <tr className="border-b border-gray-100 bg-gray-50">
            {colums.map((item) => {
              return (
                <th className="w-[25%] px-4 py-4 text-left text-sm font-medium text-gray-600">
                  {item.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {currentItems.map((item, index) => {
            return <ComponentRow item={item} index={index} />;
          })}
        </tbody>
      </table>
      <div className="mt-4 flex items-center justify-start">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="mr-2 rounded-l bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
        >
          <GrFormPrevious />
        </button>
        {Array.from(Array(totalPages).keys()).map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber + 1)}
            className={`mr-2 rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
              pageNumber + 1 === currentPage ? "bg-blue-700" : ""
            }`}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastItem >= dataGrid.length}
          className="ml-2 rounded-r bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
        >
          <MdNavigateNext />
        </button>
        <span className="ml-2">
          Registros <span className="font-bold">{data && data.length}</span>{" "}
        </span>
      </div>
    </div>
  );
};

export default TableUx;
