import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { Form1 } from "./components/form-simulador";
import { FormPruebaIne } from "./components/form-prueba-ine";
import api from "service";
import { SimuladorMasivo } from "./components/simulador-masivo";
import InputField from "components/fields/InputField";
import { SolvenciaCalculada } from "./components/solvencia-calculada";

const Simulador = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [curp, setCurp] = useState();

  const [active, setActive] = useState(1);
  const getClassActive = () => " rounded-tl-lg  bg-brand-400 text-white";

  const calculateOferta = useCallback(
    async ({
      nomina,
      universo,
      rfc,
      ficha,
      tipo,
      curp,
      genero,
      tipoLey,
      sindicato,
      job,
      dateBirthDay,
      convenio,
    }) => {
      const query = `nomina=${nomina}&universo=${universo}&curp=${curp}&rfc=${rfc}&ficha=${ficha}&tipo=${tipo}&dateBirthDay=${dateBirthDay}&genero=${genero}&tipoLey=${tipoLey}&sindicato=${sindicato}&job=${job}&convenio=${convenio}`;
      const endpoint = `${process.env.REACT_APP_URL_BASE}/solicitudes/oferta-credito?${query}`;
      setLoading(true);
      fetch(endpoint, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (json && json.message) {
            setData();
            return Swal.fire({
              title: "Aviso",
              text: json.message,
            });
          }
          setData(json);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    []
  );

  const calculateOfertaFake = useCallback(
    ({
      solvencia,
      tipo,
      dependencia,
      nomina,
      universo,
      fechaIngreso,
      dateBirthDay,
      montoLiquidar,
      genero,
      tipoLey,
      sindicato,
      job,
      convenio,
    }) => {
      const query = `solvencia=${solvencia}&tipo=${tipo}&dependencia=${dependencia}&nomina=${nomina}&universo=${universo}&fechaIngreso=${fechaIngreso}&dateBirthDay=${dateBirthDay}&montoLiquidar=${montoLiquidar}&genero=${genero}&tipoLey=${tipoLey}&sindicato=${sindicato}&job=${job}`;
      const endpoint = `${process.env.REACT_APP_URL_BASE}/solicitudes/simulador?${query}`;
      setLoading(true);
      fetch(endpoint, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (json && json.message) {
            setData();
            return Swal.fire({
              title: "Aviso",
              text: json.message,
            });
          }
          setData(json);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    []
  );
  return (
    <div className="mt-10">
      <div className="flex">
        {[
          {
            text: "Simular con cliente",
            index: 1,
          },
          {
            text: "Simular con datos fijos",
            index: 2,
          },

          {
            text: "Prueba Ine",
            index: 3,
          },
          {
            text: "Simular masivo",
            index: 4,
          },
          {
            text: "Pagos - Curp",
            index: 5,
          },
        ].map((item) => {
          return (
            <button
              className={`px-4 py-2  ${
                active === item.index ? getClassActive() : "bg-gray-200"
              }`}
              onClick={() => {
                setData();
                setActive(item.index);
              }}
            >
              {item.text}
            </button>
          );
        })}
      </div>
      {active === 1 && (
        <Form1
          calculateOferta={calculateOferta}
          data={data}
          loading={loading}
        />
      )}
      {active === 2 && (
        <Form1
          calculateOferta={calculateOfertaFake}
          data={data}
          loading={loading}
          fake={true}
        />
      )}
      {active === 3 && <FormPruebaIne />}
      {active === 4 && <SimuladorMasivo />}
      {active === 5 && (
        <div className="flex flex-col bg-white px-4 py-10 w-full bg-red-300">
          <div className="flex flex-row items-end justify-start gap-4 w-full">
            <InputField
              extra="w-[200px] border-1 border-gray-300 "
              label={"Ingresa el curp a buscar"}
              type={"text"}
              onChange={async (e) => {
                const value = e.target.value;
                setCurp(value);
              }}
            />

            <button
              className="rounded-[10px] bg-brand-500 mx-2 p-2 text-white"
              disabled={loading}
              onClick={async () => {
                const result = await api.post(
                  `solicitudes/last-payment-curp?curp=${curp}`
                );
                if (result) setData(result);
              }}
            >
              {loading ? "BUSCANDO" : "BUSCAR PAGOS"}
            </button>
          </div>

          <SolvenciaCalculada data={data} />
        </div>
      )}
    </div>
  );
};

export default Simulador;
