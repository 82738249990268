import Swal from "sweetalert2";
import SimuladorC from "./simulador";

export function Form1({ calculateOferta, data, loading, fake }) {
  return (
    <div className="mt-10 grid grid-cols-[1fr_3fr] grid-rows-[1fr] gap-2">
      <div className="justify-ceneter flex flex-col px-10">
        <form
          className="justify-ceneter flex flex-col px-10"
          onSubmit={(event) => {
            event.preventDefault();
            const form = event.target;
            const formData = new FormData(form);
            const nomina = formData.get("nomina");
            const universo = formData.get("universo");
            const rfc = formData.get("rfc");
            const ficha = formData.get("ficha");
            const tipo = formData.get("tipo");
            const curp = formData.get("curp");
            const dateBirthDay = formData.get("dateBirthDay");
            const genero = formData.get("genero");
            const tipoLey = formData.get("tipoLey");
            const sindicato = formData.get("sindicato");
            const solvencia = formData.get("solvencia");
            const dependencia = formData.get("dependencia");
            const montoLiquidar = formData.get("montoLiquidar");
            const convenio = formData.get("convenio");

            if (!nomina) {
              return Swal.fire({
                type: "error",
                title: "Ingresa la nomina",
              });
            }
            if (!universo) {
              return Swal.fire({
                type: "error",
                title: "Ingresa el universo",
              });
            }
            if (!rfc) {
              return Swal.fire({
                type: "error",
                title: "Ingresa el rfc",
              });
            }

            if (!ficha) {
              return Swal.fire({
                type: "error",
                title: "Ingresa la ficha",
              });
            }

            if (!curp) {
              return Swal.fire({
                type: "error",
                title: "Ingresa el curp",
              });
            }
            calculateOferta({
              nomina,
              universo,
              rfc,
              ficha,
              tipo,
              curp,
              dateBirthDay,
              genero,
              tipoLey,
              sindicato,
              solvencia,
              dependencia,
              montoLiquidar,
              convenio,
            });
          }}
        >
           <div className="flex flex-col">
              <label htmlFor="">Convenio</label>
              <select name="convenio" id="convenio" className="rounded-lg p-1">
              <option value={""}>Selecciona el convenio</option>
              <option value={"NOMINA GCDMX"}>NOMINA GCDMX</option>
              <option value={"NL"}>NL</option>
            </select>
            </div>

          {fake && (
            <div className="flex flex-col">
              <label htmlFor="">Solvencia</label>
              <input
                type="text"
                name="solvencia"
                id="solvencia"
                className="rounded-lg p-1"
              />
            </div>
          )}
          {fake && (
            <div className="flex flex-col">
              <label htmlFor="">Dependencia</label>
              <input
                type="text"
                name="dependencia"
                id="dependencia"
                className="rounded-lg p-1"
              />
            </div>
          )}
          <div className="flex flex-col">
            <label htmlFor="">Tipo</label>
            <select name="tipo" id="tipo" className="rounded-lg p-1">
              <option value={""}>Selecciona el tipo</option>
              <option value={"Nuevo"}>Nuevo</option>
              <option value={"Adicional"}>Adicional</option>
              <option value={"Refinanciamiento"}>Refinanciamiento</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Nomina</label>
            <select name="nomina" id="nomina" className="rounded-lg p-1">
              <option value={""}>Selecciona el tipo de nomina</option>
              <option value={1}>1</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={8}>8</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Universo</label>
            <input
              type="text"
              name="universo"
              id="universo"
              className="rounded-lg p-1"
            />
          </div>

          {!fake && (
            <>
              <div className="flex flex-col">
                <label htmlFor="">Curp</label>
                <input
                  type="text"
                  name="curp"
                  id="curp"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Rfc</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Ficha</label>
                <input
                  type="text"
                  name="ficha"
                  id="ficha"
                  className="rounded-lg p-1"
                />
              </div>
            </>
          )}
          <div className="flex flex-col">
            <label htmlFor="">Fecha de nacimiento</label>
            <input
              type="date"
              name="dateBirthDay"
              id="dateBirthDay"
              className="rounded-lg p-1"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Genero</label>
            <select name="genero" id="genero" className="rounded-lg p-1">
              <option value={""}>Selecciona el genero</option>
              <option value={"H"}>H</option>
              <option value={"M"}>M</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Tipo Ley</label>
            <input
              type="text"
              name="tipoLey"
              id="tipoLey"
              className="rounded-lg p-1"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="">Sindicato</label>
            <input
              type="text"
              name="sindicato"
              id="sindicato"
              className="rounded-lg p-1"
            />
          </div>
          {fake && (
            <>
              <div className="flex flex-col">
                <label htmlFor="">Puesto</label>
                <input
                  type="text"
                  name="job"
                  id="job"
                  className="rounded-lg p-1"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Monto a liquidar</label>
                <input
                  type="number"
                  name="montoLiquidar"
                  id="montoLiquidar"
                  className="rounded-lg p-1"
                  defaultValue={0}
                />
              </div>
            </>
          )}
          <button
            className="mt-10 rounded-[10px] bg-brand-500 p-2 text-white"
            disabled={loading}
          >
            {loading ? "CALCULANDO" : "VER OFERTA"}
          </button>
        </form>
      </div>
      <div className="rounded-[20px] bg-blue-100 py-10">
        <SimuladorC oferta={data} />
      </div>
    </div>
  );
}
