import { NumberMxn } from "helpers";
import { useMemo, useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import BtnNew from "components/btn-new";
import Swal from "sweetalert2";

export default function SimuladorC({ oferta, token, id, callBack }) {
  const [plazo, setPlazo] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [tipo, setTipo] = useState("");
  const { REACT_APP_URL_BASE } = process.env;

  const prestamo = useMemo(() => {
    if (oferta) {
      return oferta.prestamo;
    }
  }, [oferta]);

  useEffect(() => {
    if (prestamo) {
      setPlazo(prestamo.plazo);
      setTipo(prestamo?.tipo ?? "");
    }
  }, [prestamo]);

  const listDataPlazasAndAmounts = useMemo(() => {
    return prestamo?.datas ?? [];
  }, [prestamo]);

  useEffect(() => {
    if (plazo) {
      const currentPlazo = listDataPlazasAndAmounts.find(
        (item) => item.plazo === plazo
      );
      if (currentPlazo && currentPlazo.amounts) {
        const netSlider = currentPlazo?.amounts.length;
        setSliderValue(netSlider - 1);
      }
    }
  }, [plazo, listDataPlazasAndAmounts]);

  const plazos = useMemo(() => {
    if (listDataPlazasAndAmounts) {
      return listDataPlazasAndAmounts.map((item) => item.plazo);
    }
  }, [listDataPlazasAndAmounts, prestamo]);

  const listAmounts = useMemo(() => {
    if (plazo) {
      const resultFind = listDataPlazasAndAmounts.find(
        (item) => item.plazo === plazo
      );
      return resultFind?.amounts;
    }
    return [];
  }, [plazo, prestamo, sliderValue, listDataPlazasAndAmounts]);

  const currentAmount = useMemo(() => {
    if (sliderValue > -1 && listAmounts) {
      return listAmounts[sliderValue];
    }
    return null;
  }, [sliderValue, listAmounts]);

  const dispersar = useMemo(() => {
    if (currentAmount && prestamo && ["Refinanciamiento"].includes(tipo)) {
      return currentAmount.total - prestamo?.montoLiquidar ?? 0;
    } else {
      return currentAmount?.total ?? 0;
    }
  }, [currentAmount, tipo, prestamo]);

  const liquidar = useMemo(() => {
    if (prestamo && ["Refinanciamiento"].includes(tipo)) {
      return prestamo.montoLiquidar ?? 0;
    } else {
      return 0;
    }
  }, [tipo, prestamo]);

  const porcentaje = useMemo(() => {
    if (currentAmount && prestamo) return currentAmount.porcentaje;
    return 0;
  }, [currentAmount, prestamo]);

  const paymentTotal = useMemo(() => {
    if (currentAmount && prestamo) return currentAmount.pagoTotal;
    return 0;
  }, [currentAmount, prestamo, sliderValue]);

  return (
    <div className="flex  flex-col items-center p-4">
      <div className="flex w-[500px] flex-col items-center gap-2">
        <div className="flex flex-col items-center">
          <label className="text-[20px]">Tipo de oferta:</label>
          <label className="text-[20px]">
            <strong>{tipo}</strong>
          </label>
        </div>
        <div className="flex flex-col items-center">
          <label className="text-[20px]">Monto a dispersar: </label>
          <label className="text-[20px]">
            <strong>{NumberMxn(dispersar)}</strong>
          </label>
        </div>
        <div className="flex flex-col items-center">
          <label className="text-[20px]">Solvencia: </label>
          <label className="text-[20px]">
            <strong>{NumberMxn(prestamo?.solvencia ?? 0)}</strong>
          </label>
        </div>
        <div className="flex flex-col items-center">
          <label className="text-[20px]"> Monto a liquidar:</label>
          <label className="text-[20px]">
            <strong>{NumberMxn(liquidar ?? 0)}</strong>
          </label>
        </div>
        <div className="flex flex-col items-center">
          <label className="text-[20px]"> Monto del crédito:</label>
          <label className="text-[20px]">
            <strong>{NumberMxn(currentAmount?.total ?? 0)}</strong>
          </label>
        </div>
        <div className="flex flex-col items-center">
          <label className="text-[20px]"> Pago Quincenal:</label>
          <label className="text-[20px]">
            <strong>{NumberMxn(currentAmount?.monto ?? 0)}</strong>
          </label>
        </div>
      </div>
      <div className="mt-10 h-[20px]  w-[500px]">
        {listAmounts && (
          <Slider
            min={0}
            max={listAmounts.length - 1}
            value={sliderValue}
            step={1}
            onChange={(event) => {
              setSliderValue(event);
            }}
            trackStyle={{ backgroundColor: "#C280FF" }}
            handleStyle={{ borderColor: "#C280FF", backgroundColor: "#C280FF" }}
            railStyle={{ backgroundColor: "#333333" }}
            dotStyle={{ borderColor: "#C280FF" }}
            activeDotStyle={{ borderColor: "#C280FF" }}
          />
        )}
        <div className="flex w-full flex-row justify-between ">
          <label className="text-[20px]">
            {NumberMxn(
              listAmounts && listAmounts[0] ? listAmounts[0].total : 0
            )}
          </label>
          <label className="text-[20px]">
            {NumberMxn(
              listAmounts && listAmounts[listAmounts.length - 1]
                ? listAmounts[listAmounts.length - 1].total
                : 0
            )}
          </label>
        </div>
      </div>
      <div className="mt-10 flex space-x-2 overflow-x-auto p-2">
        {plazos.map((item, index) => (
          <button
            key={index}
            className={`flex h-14 w-14 items-center justify-center rounded-full
            border transition-colors duration-300 
            ${
              plazo === item
                ? "border-4 border-[#C280FF]"
                : "border-2 border-[#333333]"
            }
            hover:border-[#C280FF] hover:bg-gray-200`}
            onClick={() => setPlazo(item)}
          >
            <span
              className={`text-sm ${
                plazo === item ? "text-[#C280FF]" : "text-[#333333]"
              }`}
            >
              {item}
            </span>
          </button>
        ))}
      </div>
      <div className="flex w-[100%] flex-row  justify-end">
        {id && prestamo ? (
          <BtnNew
            visible={true}
            handleClick={() => {
              Swal.fire({
                title: "Oferta.",
                text: "se actualizara la oferta",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                icon: "warning",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const dto = {
                    tipo: tipo,
                    capacidad: prestamo?.solvencia ?? 0,
                    dispocicion: dispersar,
                    total: currentAmount?.total,
                    pago: currentAmount?.monto,
                    plazo: plazo,
                    porcentaje,
                    liquidar,
                    pagoTotal: paymentTotal,
                    contrato_renegociacion: prestamo?.contrato_renegociacion,
                    monto_liberado: prestamo?.monto_liberado,
                  };
                  const options = {
                    method: "POST",
                    headers: {
                      Authorization: "Bearer " + token,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dto),
                  };
                  const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${id}/oferta?isRecotizacion=true`;
                  fetch(endpoint, options)
                    .then((res) => res.json())
                    .finally(() => {
                      callBack && callBack();
                    });
                }
              });
            }}
          >
            ACTUALIZAR NUEVA OFERTA
          </BtnNew>
        ) : null}
      </div>
    </div>
  );
}
